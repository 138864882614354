
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      title: "加入我们",
      selectedIndex: 0,
      tabs: [
        {
          title: "联系我们",
          des: `归农集团总部地址：上海市闵行区旭辉浦江国际3号楼\n联系电话：4001288619`,
        },
        {
          title: "企业招聘",
          des: `我们正在招聘的岗位有:\n社群运营 12k-13k\n要求：\n1、具有强烈的事业心、自我驱动和目标感，具有独立思考判断决策能力、出色的社交沟通能力，具有敏锐的人性、市场洞察能力与商业思维\n2、大专学历以上、3年以上工作经历、35岁以下优质青年\n3、社群运营、市场营销、电子商务等专业优先。语言表达和文字沟通能力强。喜欢群聊、私聊，善于用互联网工具表达。对社群运营，短视频、抖音有自己独特的想法和思路。\n简历投递邮箱：guinong2020@163.com`,
        },
      ],
      qrCodeTips: "扫码上方二维码关注我们",
    };
  },
  methods: {
    handleTab(index: number) {
      console.log(index);
      if (this.selectedIndex !== index) {
        this.selectedIndex = index;
      }
    },
  },
})
export default class CommunityHeader extends Vue {}
