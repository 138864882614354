<template>
  <div id="dituContent"></div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import BMap from "BMap";
const ak = "FMGkrfQ7FoQazGxKAgYhRjEcjDbPeYpE";
import { MP } from "../utils/map.js";

@Options({
  components: {},
  data() {
    return {
      showBg: true,
    };
  },
  methods: {
    createMap(BMap) {
      var map = new BMap.Map("dituContent");
      var point = new BMap.Point(121.519907, 31.082899);
      map.centerAndZoom(point, 18);
      map.setCurrentCity("上海");

      var ctrl_nav = new BMap.NavigationControl({
        anchor: BMAP_ANCHOR_BOTTOM_LEFT,
        type: BMAP_NAVIGATION_CONTROL_LARGE,
      });
      map.addControl(ctrl_nav);

      var ctrl_ove = new BMap.OverviewMapControl({
        anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
        isOpen: 1,
      });
      map.addControl(ctrl_ove);

      var ctrl_sca = new BMap.ScaleControl({ anchor: BMAP_ANCHOR_BOTTOM_LEFT });
      map.addControl(ctrl_sca);

      // var marker = new BMap.Marker(point);
      // map.addOverlay(marker);

      var myIcon = new BMap.Icon(
        "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/index/gns.png",
        new BMap.Size(144, 66),
      );
      myIcon.setImageSize(new BMap.Size(144, 66));
      var marker = new BMap.Marker(point, {
        icon: myIcon,
      });
      // 将标注添加到地图
      map.addOverlay(marker);
    },
  },
  mounted() {
    this.$nextTick(() => {
      MP(ak)
        .then((a) => {
          // console.log(BMap);
          this.createMap(BMap);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
})
export default class ThirdMap extends Vue {}
</script>

<style scoped lang="scss">
#dituContent {
  width: 100%;
  height: 500px;
  border: #ccc solid 1px;
}
</style>