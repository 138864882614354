
import { Options, Vue } from "vue-class-component";
import RecruitmentHeader from "./Header.vue";
import ThirdMap from "@/components/ThirdMap.vue";

@Options({
  components: {
    RecruitmentHeader,
    ThirdMap,
  },
  data() {
    return {};
  },
})
export default class Recruitment extends Vue {}
