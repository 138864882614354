import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecruitmentHeader = _resolveComponent("RecruitmentHeader")!
  const _component_ThirdMap = _resolveComponent("ThirdMap")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_RecruitmentHeader)
    ]),
    _createVNode(_component_ThirdMap)
  ], 64))
}